import _uniq from 'lodash.uniq';

import Button from 'lib/common/components/Button';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { EmailSendTypes } from 'lib/common/hooks/useEmail/api/sendEmail';
import TaskContentTypes from 'lib/common/types/TaskContentTypes';
import contactStates from 'lib/common/constants/contactStates';
import { getSanitizedEmail } from 'lib/common/utils/email/getSanitizedEmail';
import { LogEvents, logger } from 'lib/common/components/LoggerController';
import { getTaskContact } from 'lib/common/utils/getTask';
import IEmailMetadata from 'lib/common/types/email/EmailMetadata';
import getContactAttributes from 'lib/common/utils/getContactAttributes';
import TEmailTask from 'lib/common/types/EmailTask';

import TaskFooter from '../TaskFooter';
import TaskFooterRow from '../TaskFooterRow';
import TransferFooterButtons from '../TransferFooterButtons';
import { buildReplyForwardHtml } from './utils';
import { useEffect } from 'react';

interface IEmailFooterProps {
  task: TEmailTask;
  content: any;
  attachments: any;
  metadata?: IEmailMetadata;
  isSmallWidth: boolean;
}

function getEmailArrayFromEmailsString(emailsString?: string) {
  if (!emailsString) {
    return [];
  }

  return emailsString.split(',');
}

function getToTagFromEmail(emailAddress: string) {
  return { value: getSanitizedEmail(emailAddress), valid: true };
}

const getUniqueExternalEmailTags = (emailAddresses: string[], neonEmailTo: string) =>
  _uniq(emailAddresses)
    .filter((emailAddress: string) => emailAddress !== neonEmailTo)
    .map(getToTagFromEmail);

function getReplyToValue({
  replyAll,
  neonEmailTo,
  allEmailRecipientsArray,
  ccEmailRecipientsArray,
  fromEmailAddress
}: {
  replyAll?: boolean;
  neonEmailTo: string;
  allEmailRecipientsArray: string[];
  ccEmailRecipientsArray: string[];
  fromEmailAddress: string;
}) {
  const to = {
    allValid: true,
    tags: [getToTagFromEmail(fromEmailAddress)]
  };

  if (!replyAll) {
    return { to };
  }

  return {
    to: {
      allValid: true,
      tags: getUniqueExternalEmailTags(allEmailRecipientsArray, neonEmailTo)
    },
    cc: { allValid: true, tags: getUniqueExternalEmailTags(ccEmailRecipientsArray, neonEmailTo) }
  };
}

export default function Email({ task, content, metadata, isSmallWidth }: IEmailFooterProps) {
  const {
    actions: { hangupAgent, setTaskContent },
    state: { tasks }
  } = useContactContext();

  const {
    neonEmailCcRecipients,
    neonEmailAllRecipients,
    neonEmailTo = 'Unknown',
    emailAddress: fromEmailAddress,
    neonEmailSubject
  } = getContactAttributes(
    { task },
    'neonEmailTo',
    'neonEmailCcRecipients',
    'neonEmailAllRecipients',
    'emailAddress',
    'neonEmailSubject'
  );

  const emailSubject = neonEmailSubject || 'No Subject';

  const allRecipients = getEmailArrayFromEmailsString(neonEmailAllRecipients);
  const ccRecipients = getEmailArrayFromEmailsString(neonEmailCcRecipients);

  useEffect(() => console.log('NNDEBUG Email', { neonEmailTo }), []);

  const replyToEmail =
    ({ replyAll }: { replyAll?: boolean } = {}) =>
    async () => {
      // If no from email address, the button is disabled so will never get in here
      if (!fromEmailAddress) {
        return;
      }

      setTaskContent(
        task.taskId,
        TaskContentTypes.EMAIL,
        {
          originalEmail: { emailSubject, content, neonEmailTo, emailAddress: fromEmailAddress },
          type: EmailSendTypes.REPLY,
          fromAddressId: null,
          subject: emailSubject ? `Re: ${emailSubject}` : '',
          ...getReplyToValue({
            replyAll,
            allEmailRecipientsArray: [fromEmailAddress, ...allRecipients],
            ccEmailRecipientsArray: ccRecipients,
            fromEmailAddress,
            neonEmailTo
          }),
          originalEmailHtml: buildReplyForwardHtml({
            content,
            emailSubject,
            emailAddress: fromEmailAddress,
            allEmailRecipientsArray: [neonEmailTo, ...allRecipients],
            ccEmailRecipientsArray: ccRecipients
          }),
          metadata
        },
        { replace: true }
      );

      setTaskContent(task.taskId, TaskContentTypes.STATUS, contactStates.DRAFT);

      logger.info(replyAll ? LogEvents.EMAIL.REPLY_ALL_CREATED : LogEvents.EMAIL.REPLY_CREATED, {
        emailTaskId: task.taskId,
        contactId: getTaskContact(tasks, task.taskId)
      });
    };

  const forwardEmail = () => {
    setTaskContent(
      task.taskId,
      TaskContentTypes.EMAIL,
      {
        originalEmail: { emailSubject, content, neonEmailTo, emailAddress: fromEmailAddress || 'Unknown' },
        type: EmailSendTypes.FORWARD,
        fromAddressId: null,
        subject: `Fw: ${emailSubject}`,
        originalEmailHtml: buildReplyForwardHtml({
          content,
          emailSubject,
          emailAddress: fromEmailAddress || 'Unknown',
          allEmailRecipientsArray: [neonEmailTo, ...allRecipients],
          ccEmailRecipientsArray: ccRecipients
        }),
        metadata
      },
      { replace: true }
    );

    setTaskContent(task.taskId, TaskContentTypes.STATUS, contactStates.DRAFT);

    logger.info(LogEvents.EMAIL.FORWARD_CREATED, {
      emailTaskId: task.taskId,
      contactId: getTaskContact(tasks, task.taskId)
    });
  };

  return (
    <TaskFooter isSmallWidth={isSmallWidth}>
      <TaskFooterRow isSmallWidth={isSmallWidth}>
        <TransferFooterButtons taskId={task.taskId} connectionValue={task.connectionValue}>
          <Button
            icon="faReply"
            tooltip="Reply"
            styleType="NEUTRAL"
            round
            onClick={replyToEmail({ replyAll: false })}
            disabled={!content || !fromEmailAddress}
            size="small"
          />
        </TransferFooterButtons>
      </TaskFooterRow>
      <TaskFooterRow isSmallWidth={isSmallWidth} primaryButtonIndex={1}>
        <Button
          icon="faReplyAll"
          tooltip="Reply all"
          styleType="NEUTRAL"
          round
          onClick={replyToEmail({ replyAll: true })}
          disabled={!content || !fromEmailAddress}
          size="small"
        />
        <Button
          round
          size="medium"
          onClick={() => hangupAgent(task.taskId)}
          icon="faXmark"
          styleType="DANGER"
          tooltip="Close the email"
          asyncAction
          successTimeoutSeconds={0}
          preventDoubleClick
        />
        <Button
          icon="faArrowTurnRight"
          tooltip="Forward"
          styleType="NEUTRAL"
          round
          size="small"
          onClick={forwardEmail}
          disabled={!content}
        />
      </TaskFooterRow>
    </TaskFooter>
  );
}
